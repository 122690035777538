<template>
  <div class="table">
    <!-- <el-table-bar :fixed="true" :static="true"> -->
    <el-table
      ref="multipleTable"
      :data="tableData"
      :show-summary="summary"
      :summary-method="getSummaries"
      @selection-change="handleSelectionChange"
      style="width: 100%; height: 100%"
    >
      <el-table-column type="selection" v-if="checkboxShow"> </el-table-column>
      <template v-for="item in tableConfig">
        <template v-if="item.prop == 'goods_img'">
          <el-table-column :key="item.label" align="center" :label="item.label">
            <template slot-scope="scope">
              <el-image
                :src="scope.row.goods_img"
                style="width: 32px; height: 32px; margin: 0 auto"
                v-if="scope.row.goods_img != null"
                :preview-src-list="scope.row.goods_imgs"
              ></el-image>
              <img
                src="http://www.chuansmart.com/storage/shop/baicai.png"
                style="width: 32px; height: 32px; margin: 0 auto"
                v-if="scope.row.goods_img == null"
              />
            </template>
          </el-table-column>
        </template>
        <template v-else>
          <el-table-column
            :key="item.label"
            align="center"
            :prop="item.prop"
            :label="item.label"
            :min-width="item.width ? item.width : 160"
          >
          </el-table-column>
        </template>
      </template>
      <el-table-column
        v-if="actionList && actionList.length > 0"
        property="action"
        label="操作"
        min-width="200"
        align="center"
        fixed="right"
        border
      >
        <template slot-scope="{ row }">
          <div class="btn-group">
            <template>
              <span
                v-for="(item, index) in actionList"
                :key="index"
                class="btn"
              >
                <el-button
                  type="text"
                  size="mini"
                  @click="handleAction(JSON.stringify(row), item.label)"
                  v-if="
                    item.statusList.length == 0 ||
                      (item.statusList.length > 0 &&
                        item.statusList.includes(row.status))
                  "
                >
                  <!-- v-show="
                  (item.is_show_mis == 1 &&
                    item.statusList.includes(row.status) &&
                    codes.includes(item.roles)) ||
                    (item.statusList.includes(row.status) &&
                      codes.includes(item.roles)) ||
                    (codes.includes(item.roles) && item.statusList.length == 0)
                " -->
                  {{ item.label }}
                </el-button>
              </span>

              <!-- v-show="
                  (!item.statusList.length ||
                    item.statusList.includes(row.status)) &&
                    codes.includes(item.roles)
                " -->
            </template>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- </el-table-bar> -->
  </div>
</template>
<script>
// import { reviewOrderDetail, storeOrderView } from '@/api/orderManagePage2';
// import { filterOrderStatus, filterPermission } from '@/global';
import { tableHeaderStyle } from "@/utils";
// import func from 'vue-editor-bridge';

export default {
  props: {
    // 1普通订单 2商户订单
    form: {
      type: Number,
      default: 1,
    },
    tableConfig: {
      type: Array,
      required: true,
    },
    tableData: {
      type: Array,
      required: true,
    },
    actionList: {
      type: Array,
      required: false,
    },
    summaryData: {
      type: Array,
      required: false,
    },
    summary: {
      type: Boolean,
      default: false,
    },
    checkboxShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 0待付款 10待发货 11待成团 12待成团发货 20待收货 21待核销 30待评价 31已评价 -10已关闭 -11成团失败
      // actionList: [],
      rowInfo: {},
      selectList: [], // 被选中的list
      codes: [],
      // checkboxShow: false
    };
  },

  // watch:{
  //   this.summaryData
  // },
  mounted() {
    console.log(this.$route.name);
    // if (
    //   this.$route.name == "MaterialManage" ||
    //   this.$route.name == "mallOrderManage"
    // ) {
    //   this.checkboxShow = true;
    // } else {
    //   this.checkboxShow = false;
    // }

    //  this.$parent.$refs
  },
  methods: {
    fixed(prop) {},
    getSummaries() {
      // const { columns, data } = param;
      const sums = [];
      sums[0] = "汇总";
      console.log(this.summaryData);
      if (this.summaryData) {
        this.summaryData.forEach((ele) => {
          sums[ele.index] = ele.total;
        });
      }
      console.log(sums);
      return sums;
    },
    handleSelectionChange(e) {
      console.log(e);
      this.selectList = e;
      let id = [];
      e.forEach(function(ele) {
        id.push(ele.id);
      });
      console.log(id);
      this.selectListId = id ? id.join(",") : "";
      this.$emit("getCheckedList", {
        selectListId: this.selectListId,
        selectList: this.selectList,
        selectIdArr: id,
      });
    },
    handleAction(rows, label) {
      let row = JSON.parse(rows);
      let routeName = this.$route.name;
      switch (label) {
        case "查看详情":
          if (["screen_ad"].includes(routeName)) {
            let activeName = this.$parent.$parent.$parent.$parent.activeName;
            console.log(activeName);
            if (activeName == "third") {
              this.$emit("detailMaterial", row);
            } else {
              this.$emit("detailTemplate", row);
            }
          }
          break;
        case "编辑":
          if (["screen_ad"].includes(routeName)) {
            let activeName = this.$parent.$parent.$parent.$parent.activeName;
            console.log(activeName);
            if (activeName == "second") {
              this.$emit("editTemplate", row);
            } else if (activeName == "third") {
              this.$emit("editMaterial", row);
            }
          } else if (["supervise"].includes(routeName)) {
            this.$emit("editSupervise", row);
          }
          break;
        case "解绑":
          if (["screen_ad"].includes(routeName)) {
            this.$confirm("确定解绑吗？", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              this.$emit("unbind", row.id);
            });
          }
          break;
        case "下发":
          if (["screen_ad"].includes(routeName)) {
            this.$emit("send", row.id);
          }
          break;
        case "平台设置":
          if (["supervise"].includes(routeName)) {
            this.$emit("setSupervise", row);
          }
          break;
        case "复制":
          if (["supervise"].includes(routeName)) {
            this.$emit("copySupervise", row);
          }
          break;
      }
    },
  },
};
</script>
<style>
.el-table thead tr th {
  background-color: #e9f2f1 !important;
  color: #4c4d55 !important;
  font-weight: 500 !important;
}
</style>
<style lang="less" scoped>
.holder {
  .two {
    span:last-child {
      margin-left: 30px;
    }
  }
  .three {
    .top {
      display: flex;
      align-items: center;
      .img-holder {
        margin-bottom: 5px;
        width: 80px;
        height: 80px;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .info {
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
      }
    }
  }
}
.btn-group {
  // white-space: normal;
  .el-button {
    // background-color: transparent;
  }
}
.textflow {
  max-width: 200px;
  // overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rough {
  font-weight: 500;
}
</style>
<style lang="less">
.btn {
}
// .el-table__body-wrapper {
//   z-index: 2;
// }
// .el-table--scrollable-x .el-table__body-wrapper {
//   overflow: scroll !important;
// }
// 去掉eltable的hover效果

// tr {
//   pointer-events: none;
// }
</style>
