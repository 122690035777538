import QueryConditions from "@/components/queryConditions";

export default {
  components: {
    QueryConditions,
  },
  data() {
    return {
      tableData: [],
      tableConfig: [],
      queryConditionsObj: {},
      filterRuleForm: {},
      total: 0,
      filterRuleForm_parse: {},
      tips: false,
    };
  },
  mounted() {
    console.log(this.filterRuleForm, "this.filterRuleForm");
  },
  // deactivated() {
  //   this.pageNum = 1;
  // },
  methods: {
    onQueryChange(val) {
      console.log(val);
      val.start = 0;
      this.$refs.pagination.currentPage = 0;
      // 点击查询的时候，是从第一页开始
      const { start, length, action } = this.filterRuleForm;
      console.log("val:", val);
      this.filterRuleForm = { start, length, action, ...val };
      console.log(this.filterRuleForm, "this.filterRuleForm");
      console.log("12121");
      this.filterRuleForm_parse = this.filterRuleForm;
      this.filterRequestWrapper();
      this.tips = true;
    },
    handleCurrentChange(val) {
      console.log(val);
      console.log(this.filterRuleForm);
      this.filterRuleForm = {
        ...this.filterRuleForm,
        start: val.start,
        length: val.length,
      };
      this.filterRequestWrapper();
      // console.log(this.filterRuleForm_parse);
      // console.log(val);
      // const { start, length } = val;
      // console.log(start);
      // // this.filterRuleForm.start = start;
      // console.log(this.filterRuleForm);
      // this.filterRuleForm_parse = {
      //   ...this.filterRuleForm_parse,
      //   start: start,
      //   length: length,
      // };
      // const filterForm = {
      //   start: start,
      //   length: length,
      //   action: this.filterRuleForm.action,
      // };
      // this.filterRuleForm = this.tips ? this.filterRuleForm_parse : filterForm;
      // console.log(this.filterRuleForm);
      // this.filterRequestWrapper();

      // if (this.$refs.table) {
      //   this.$refs.table.$refs.multipleTable.$refs.bodyWrapper.scrollTop = 0;
      // }
    },
  },
};
