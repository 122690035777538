<template>
  <div class="box-card">
    <div class="query-wrapper">
      <template>
        <div class="query-row">
          <div
            class="row-item"
            v-for="(timeSelectItem, idx) of timeSelectList"
            :key="idx"
          >
            <el-select
              v-if="timeSelectItem.filterType"
              v-model="filterRuleForm[timeSelectItem.filterRuleFormValue]"
              class="input"
              slot="prepend"
              placeholder="请选择时间"
              @change="
                selectTrigger($event, timeSelectItem.filterRuleFormValue)
              "
              size="small"
            >
              <el-option
                v-for="item in timeSelectItem.filterType"
                :key="item.label"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-date-picker
              v-model="confirmedTime"
              :value-format="timeSelectItem.format || 'yyyy-MM-dd HH:mm:ss'"
              :type="
                timeSelectItem.format === 'yyyy-MM-dd'
                  ? 'daterange'
                  : 'datetimerange'
              "
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
              size="small"
            ></el-date-picker>
          </div>
          <div
            class="row-item"
            v-for="selectItem of selectList"
            :key="selectItem.name"
          >
            <el-select
              :style="{
                'min-width': selectItem.width ? selectItem.width : 160 + 'px',
              }"
              v-model="filterRuleForm[selectItem.filterRuleFormValue]"
              :filterable="
                selectItem.filterable ? selectItem.filterable : false
              "
              :placeholder="
                selectItem.placeholder
                  ? selectItem.placeholder
                  : '请选择' + selectItem.name
              "
              :clearable="selectItem.clearable ? false : true"
              class="select"
              size="small"
            >
              <el-option
                v-for="item in selectItem.filterType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <!-- 搜索的筛选filterSelect -->
          <div
            class="row-item"
            v-for="filterItem of filterSelect"
            :key="filterItem.name"
          >
            <!-- <span class="filter-text">{{ selectItem.name }}：</span> -->
            <el-select
              v-model="filterRuleForm[filterItem.filterRuleFormValue]"
              filterable
              clearable
              remote
              reserve-keyword
              :remote-method="
                (query) => remoteMethod(query, filterItem.filterRuleFormValue)
              "
              :placeholder="'请搜索' + filterItem.name"
              class="select"
              size="small"
            >
              <el-option
                v-for="item in filterItem.filterType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <!-- 多选的选项 -->
          <div
            class="row-item"
            v-for="multiItem of multiSelectList"
            :key="multiItem.name"
          >
            <el-select
              v-model="filterRuleForm[multiItem.filterRuleFormValue]"
              multiple
              collapse-tags
              :placeholder="'请选择' + multiItem.name"
              size="small"
            >
              <el-option
                v-for="item in multiItem.filterType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="row-item" v-if="threeLevelList.length">
            <el-cascader
              v-model="threeLevelValue"
              :placeholder="'请选择商品类目'"
              :options="threeLevelList"
              @change="handleChange"
              size="small"
            ></el-cascader>
          </div>
          <!-- 选择省市区 -->
          <div class="row-item" v-if="cityInfo.need">
            <el-cascader
              v-model="selectedOptions"
              :placeholder="`请选择${selectCityLabel[cityInfo.level]}`"
              :options="cascaderData"
              @active-item-change="handleItemChange"
              :props="{
                value: 'areaCode',
                label: 'areaName',
                children: 'cities',
              }"
              size="small"
            ></el-cascader>
          </div>
          <div
            class="row-item"
            v-for="filterItem of filterList"
            :key="filterItem.filterRuleFormValue"
          >
            <el-input
              :placeholder="filterItem.name + '(支持模糊查询)'"
              v-model="filterRuleForm[filterItem.filterRuleFormValue]"
              clearable
              :style="{
                'min-width': filterItem.width ? filterItem.width : 220 + 'px',
              }"
              size="small"
            ></el-input>
          </div>
          <div class="row-item">
            <el-button size="mini" type="primary" @click="handleQuery">
              查询
            </el-button>
          </div>
          <div class="row-item">
            <el-button
              size="mini"
              type="primary"
              plain
              @click="resetFilterForm('ruleForm')"
            >
              清空
            </el-button>
          </div>
          <!-- 批量操作的一些按钮 -->
          <div class="row-item">
            <slot name="actions"></slot>
          </div>
        </div>
      </template>
      <!-- <div class="query-row">
        <div class="row-item">
          <el-button size="mini"
                     type="primary"
                     @click="handleQuery">查询</el-button>
        </div>
        <div class="row-item">
          <el-button size="mini"
                     type="primary"
                     plain
                     @click="resetFilterForm('ruleForm')">重置</el-button>
        </div>
        批量操作的一些按钮
        <div class="row-item">
          <slot name="actions"></slot>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
// import SanJiangSearch from '@/components/sjSearch';
// import SelectYcGoodsCategory from '@/components/selectYcGoodsCategory';
import {
  transTime,
  tableFormatter,
  getFormatRuleForm,
  judgeObject2Boolean,
  regFenToYuan,
} from "@/utils";
// import { getAreaList } from '@/api/area';

export default {
  name: "QueryConditions",
  components: {
    // SanJiangSearch,
    // SelectYcGoodsCategory
  },
  props: {
    needCategory: {
      type: Boolean,
      default: false,
    },
    selectList: {
      type: Array,
      default() {
        return [];
      },
    },
    filterSelect: {
      type: Array,
      default() {
        return [];
      },
    },
    multiSelectList: {
      type: Array,
      default: () => [],
    },
    timeSelectList: {
      type: Array,
      default() {
        return [];
      },
    },
    filterList: {
      type: Array,
      default() {
        return [];
      },
    },
    parentFilterRuleForm: {
      type: Object,
      default() {
        return {};
      },
    },
    threeLevelList: {
      type: Array,
      default: () => [],
    },
    cityInfo: {
      // 城市选择信息，need代表是否需要选择省市区，level为1代表只有一级也就是省，2代表2级也就是省市，3代表三级也就是省市区
      type: Object,
      default: () => {
        return {
          need: false,
          level: 3,
        };
      },
    },
  },
  data() {
    return {
      confirmedTime: [],
      filterRuleForm: {},
      threeLevelValue: [],
      cascaderData: [],
      selectedOptions: [],
      selectCityLabel: { 1: "省", 2: "省市", 3: "省市区" },
    };
  },
  watch: {
    selectedOptions(newVal, oldVal) {
      console.log("newVal:", newVal);
      this.$emit("getCityCode", newVal);
    },
    // filterRuleForm: {

    //   handler(message) {
    //     console.log(message, "message");
    //     this.filterRuleForm = this.filterRuleForm;
    //   },
    //   deep: true
    // }
  },
  mounted() {
    console.log(this.parentFilterRuleForm);
    this.filterRuleForm = this.parentFilterRuleForm;
    // 加载的时候获取省
    // this.getNodes();
  },
  methods: {
    resetFilterForm(formName) {
      // this.reload();
      this.filterRuleForm = {};
      this.confirmedTime = [];
      this.filterRuleForm.begin_time = "";
      this.filterRuleForm.end_time = "";
      console.log(this.filterRuleForm);
    },
    remoteMethod(query, filterType) {
      console.log(query);
      console.log(filterType);
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          if (filterType == "mobile") {
            this.$emit("fGetMobileList", query);
          } else if (filterType == "card_id") {
            this.$emit("fGetCardList", query);
          } else if (filterType == "product_id") {
            this.$emit("fGetProductList", query);
          } else if (filterType == "orgz_id") {
            this.$emit("fGetOrgzList", query);
          }
        }, 200);
      } else {
        this.options = [];
      }
    },
    handleFilterRuleForm() {
      console.log("1212");
      console.log(this.confirmedTime);
      if (this.confirmedTime) {
        this.filterRuleForm.begin_time = this.confirmedTime[0] / 1000;
        this.filterRuleForm.end_time = this.confirmedTime[1] / 1000;
      } else {
        this.filterRuleForm.begin_time = "";
        this.filterRuleForm.end_time = "";
      }

      // if (judgeObject2Boolean(this.confirmedTime)) {
      //   if (
      //     this.timeSelectList[0].beginTime &&
      //     this.timeSelectList[0].endTime
      //   ) {
      //     [
      //       this.filterRuleForm[this.timeSelectList[0].beginTime],
      //       this.filterRuleForm[this.timeSelectList[0].endTime],
      //     ] = this.confirmedTime;
      //   } else {
      //     [
      //       this.filterRuleForm.timeBegin,
      //       this.filterRuleForm.timeEnd,
      //     ] = this.confirmedTime;
      //   }
      // } else {
      //   console.log(273);
      //   console.log(this.filterRuleForm);
      //   this.confirmedTime = [];
      //   this.filterRuleForm.begin_time = "";
      //   this.filterRuleForm.end_time = "";
      // }
    },
    handleQuery() {
      this.handleFilterRuleForm();
      console.log(this.filterRuleForm);
      this.filterRuleForm.start = 0;
      this.$emit("query", this.filterRuleForm);
    },
    handleDownload() {
      // 下载的时候，实际上是调用筛选的条件，然后在调下载接口下载
      this.handleFilterRuleForm();
      this.$emit("download", this.filterRuleForm);
    },
    // 批量导出订单
    handleExportOrder() {
      this.handleFilterRuleForm();
      this.$emit("exportOrder", this.filterRuleForm);
    },
    // 批量导出商品
    handleExportGood() {
      this.handleFilterRuleForm();
      this.$emit("exportGood", this.filterRuleForm);
    },
    selectTrigger(val, type) {},
    // 有选择商品类目的时候用
    handleSelectYcGoodsCategory(categoryName, categoryId) {
      // this.filterRuleForm.categoryId = categoryId[categoryId.length - 1];
      this.filterRuleForm.categoryChain = categoryId.join("-");
    },
    handleAdd() {
      this.$emit("add");
    },
    handleDeliver() {
      this.$emit("deliver");
    },
    handleAudit() {
      this.$emit("audit");
    },
    handleChange(e) {
      console.log(e);
    },
    handleRecharge() {
      this.$emit("recharge");
    },
    async getNodes(val) {
      let idArea;
      let sizeArea;
      if (!val) {
        idArea = "000000";
        sizeArea = 0; // 0 代表省 1代表省市 2代表省市区
      } else if (val.length === 1) {
        idArea = val[0];
        sizeArea = val.length; // 0 代表省 1代表省市 2代表省市区
      } else if (val.length === 2) {
        idArea = val[1];
        sizeArea = val.length; // 0 代表省 1代表省市 2代表省市区
      }
      let result = await getAreaList({
        pageNum: 0,
        pageSize: 1000,
        parentCode: idArea,
      });
      let items = result.list;
      if (sizeArea === 0) {
        // 初始化 加载一级 省
        this.cascaderData = items.map((item, i) => {
          return {
            areaCode: item.areaCode,
            areaName: item.areaName,
            //  只有选择省市（也就是this.cityInfo.level为2）的时候，才会有cityes
            cities: this.cityInfo.level >= 2 ? [] : null,
          };
        });
      } else if (sizeArea === 1) {
        // 点击一级 加载二级 市
        this.cascaderData.map((provinceInfo, i) => {
          if (provinceInfo.areaCode === val[0]) {
            if (!provinceInfo.cities.length) {
              provinceInfo.cities = items.map((item, i) => {
                return {
                  areaCode: item.areaCode,
                  areaName: item.areaName,
                  //  只有选择省市区（也就是this.cityInfo.level为3）的时候，才会有cityes
                  cities: this.cityInfo.level >= 3 ? [] : null,
                };
              });
            }
          }
        });
      } else if (sizeArea === 2) {
        // 点击二级 加载三级 区
        this.cascaderData.map((provinceInfo, i) => {
          if (provinceInfo.areaCode === val[0]) {
            provinceInfo.cities.map((cityInfo, i) => {
              if (cityInfo.areaCode === val[1]) {
                if (!cityInfo.cities.length) {
                  cityInfo.cities = items.map((item, i) => {
                    return {
                      areaCode: item.areaCode,
                      areaName: item.areaName,
                    };
                  });
                }
              }
            });
          }
        });
      }
    },
    handleItemChange(val) {
      // 点击选择省市区
      this.getNodes(val);
    },
  },
};
</script>
<style lang="less" scoped>
.el-card.is-always-shadow {
  margin-bottom: 10px;
}
/deep/ .el-select .el-input--medium .el-input__inner {
  width: 160px;
}
.query-row {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  .row-item {
    margin: 5px 10px 0 0;
  }
}
/deep/ .el-select__tags {
  flex-wrap: nowrap !important;
}
.box-card {
  width: 100%;
  display: flex;
  margin-bottom: 16px;
}
</style>
