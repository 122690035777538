<template>
  <div :class="{ hidden: hidden }" class="pagination-container">
    <el-pagination
      :background="background"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      :layout="layout"
      :page-sizes="pageSizes"
      :total="total"
      v-bind="$attrs"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import { scrollTo } from "@/utils/scroll-to";

export default {
  name: "Pagination",
  props: {
    total: {
      required: true,
      type: Number,
    },
    start: {
      type: Number,
      default: 0,
    },
    length: {
      type: Number,
      default: 10,
    },
    pageSizes: {
      type: Array,
      default() {
        return [5, 10, 20, 30, 50];
      },
    },
    layout: {
      type: String,
      default: "prev, pager, next, total,sizes,jumper",
    },
    background: {
      type: Boolean,
      default: true,
    },
    autoScroll: {
      type: Boolean,
      default: true,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    // currentPage: {
    //   get(val) {
    //     console.log(val);
    //     return this.start / 10 + 1;
    //   },
    //   set(val) {
    //     console.log(val);
    //     this.$emit("update:start", (val - 1) * 10);
    //   }
    // },
    pageSize: {
      get() {
        return this.length;
      },
      set(val) {
        this.$emit("update:length", val);
      },
    },
  },
  watch: {
    // 监听功能：用来监听分页的第几页发生的变化
    currentPage(val) {
      console.log(val);

      this.currentPage = val;
    },
  },

  methods: {
    transformPageParams(size, current) {
      const current1 = current ? current : 1;
      console.log(size, current1);
      const pager = { start: size * (current1 - 1), length: size };
      return pager;
    },
    handleSizeChange(val) {
      console.log(val);
      this.currentPage = 1;
      const pager = this.transformPageParams(val, this.currentPage);
      this.$emit("pagination", pager);
      if (this.autoScroll) {
        scrollTo(0, 800);
      }
    },
    handleCurrentChange(val) {
      const pager = this.transformPageParams(this.pageSize, val);

      this.$emit("pagination", pager);

      if (this.autoScroll) {
        scrollTo(0, 800);
      }
    },
  },
};
</script>

<style scoped>
.pagination-container {
  background: #fff;
  padding: 20px 0px;
  text-align: right;
}
.pagination-container.hidden {
  display: none;
}
</style>
